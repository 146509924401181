import { useState } from "react";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import { useBidPartnerContext } from "../../_hooks/useBidPartnerContext";
import BidPartnerListModal from "./BidPartnerListModal";
import List from "./List";
import RequestEmailToPartner from "./RequestEmailToPartner";

export default function PartnerList({
  bidDetail,
  canEdit,
  hasExporterInfo,
}: {
  bidDetail: AdminBidDetail;
  canEdit: boolean;
  hasExporterInfo: boolean;
}) {
  const [showsBidPartnerModal, setShowsBidPartnerModal] = useState(false);

  const { showsRequestEmailToPartner } = useBidPartnerContext();

  // 수입과 수출에서 반대 조건을 가진다. 수입cc(해외, 국내) 수입pp(해외만) 수출cc(해외만), 수출pp(해외, 국내)
  const showsDomesticPartner =
    (bidDetail.isImport &&
      bidDetail.quotationsUser[0].freightPaymentType === "cc") ||
    (!bidDetail.isImport &&
      bidDetail.quotationsUser[0].freightPaymentType === "pp");

  const showsForeignPartner =
    (bidDetail.isImport &&
      (bidDetail.quotationsUser[0].freightPaymentType === "cc" ||
        bidDetail.quotationsUser[0].freightPaymentType === "pp")) ||
    (!bidDetail.isImport &&
      (bidDetail.quotationsUser[0].freightPaymentType === "cc" ||
        bidDetail.quotationsUser[0].freightPaymentType === "pp"));

  const getTransportType = () => {
    if (bidDetail.freightType === "FCL") {
      return "FCL";
    }
    if (
      bidDetail.freightType === "LCL" &&
      bidDetail.serviceType === "consolidation"
    ) {
      return "EXPRESS";
    }
    if (bidDetail.freightType === "AIR") {
      return "AIR";
    }
    return "LCL";
  };

  return (
    <>
      {showsDomesticPartner && (
        <List
          type="domestic"
          canEdit={canEdit}
          setShowsBidPartnerModal={setShowsBidPartnerModal}
          hasExporterInfo={hasExporterInfo}
          bidDetail={bidDetail}
        />
      )}

      {showsForeignPartner && (
        <List
          type="foreign"
          canEdit={canEdit}
          setShowsBidPartnerModal={setShowsBidPartnerModal}
          hasExporterInfo={hasExporterInfo}
          bidDetail={bidDetail}
        />
      )}

      {showsBidPartnerModal && (
        <BidPartnerListModal
          setShowsBidPartnerModal={setShowsBidPartnerModal}
          showsBidPartnerModal={showsBidPartnerModal}
          transportMode={getTransportType()}
          region={bidDetail.locale}
        />
      )}

      {showsRequestEmailToPartner && <RequestEmailToPartner />}
    </>
  );
}
