import { Control, Controller, FormState } from "react-hook-form";
import { colors, Grid, Typography } from "@mui/material";

import { REQUEST_PARTNER_EMAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

export default function CompanyNameForm({
  control,
  errors,
  bidUser,
}: {
  control: Control<REQUEST_PARTNER_EMAIL_REQ>;
  errors: FormState<REQUEST_PARTNER_EMAIL_REQ>["errors"];
  bidUser: AdminBidDetail["user"];
}) {
  return (
    <>
      <Typography variant="h6" component="div">
        화주사 회사명(영문)
      </Typography>

      <Grid item>
        {bidUser.engCompany ? (
          <Typography
            variant="subtitle1"
            component="div"
            color={colors.grey[800]}
          >
            {bidUser.engCompany}
          </Typography>
        ) : (
          <>
            <Controller
              control={control}
              name={"engCompany"}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <TextField
                    {...field}
                    variant={"standard"}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    inputRef={ref}
                    error={
                      errors.engCompany && errors.engCompany.type === "required"
                    }
                    helperText={
                      errors.engCompany &&
                      errors.engCompany.type === "required" &&
                      "필수 입력입니다."
                    }
                  />
                );
              }}
            />
          </>
        )}
      </Grid>
    </>
  );
}
