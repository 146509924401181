import { Dispatch, SetStateAction } from "react";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import ForExportation from "./ForExportation";
import ForImportation from "./ForImportation";

const BidCopyModal = ({
  showsBidCopyModal,
  setShowsBidCopyModal,
  bidDetail,
  hasExporterInfo,
}: {
  showsBidCopyModal: boolean;
  setShowsBidCopyModal: Dispatch<SetStateAction<boolean>>;
  bidDetail: AdminBidDetail;
  hasExporterInfo: boolean;
}) => {
  const { status, projectStatus } = bidDetail;

  const shipmentType = bidDetail.isImport ? "importation" : "exportation";

  return (
    <>
      {shipmentType === "importation" && (
        <ForImportation
          showsBidCopyModal={showsBidCopyModal}
          setShowsBidCopyModal={setShowsBidCopyModal}
          bidDetail={bidDetail}
          hasExporterInfo={hasExporterInfo}
        />
      )}

      {shipmentType === "exportation" && (
        <ForExportation
          showsBidCopyModal={showsBidCopyModal}
          setShowsBidCopyModal={setShowsBidCopyModal}
          bidDetail={bidDetail}
          hasExporterInfo={hasExporterInfo}
        />
      )}
    </>
  );
};

export default BidCopyModal;
