import { useCallback, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";

import {
  KeyOfUnion,
  ReturnTypeOfUnion,
} from "@sellernote/_shared/src/types/common/customUtilityTypes";
import {
  BidCopyTargetRecordForExportation,
  BidCopyTargetRecordForImportation,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

export default function CopyTargetOfNewBid<
  T extends
    | BidCopyTargetRecordForImportation
    | BidCopyTargetRecordForExportation
>({
  copyTargetRecord,
  setCopyTargetRecord,
  defaultCopyTargetRecord,
  optionList,
}: {
  copyTargetRecord: T;
  setCopyTargetRecord: (v: T) => void;
  defaultCopyTargetRecord: T;
  optionList: {
    label: string;
    value: KeyOfUnion<T>;
    disabled?: boolean;
  }[];
}) {
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return setCopyTargetRecord({
        ...copyTargetRecord,
        [event.target.name]: event.target.checked,
      });
    },
    [copyTargetRecord, setCopyTargetRecord]
  );

  useEffect(() => {
    setCopyTargetRecord(defaultCopyTargetRecord);
  }, [defaultCopyTargetRecord, setCopyTargetRecord]);

  return (
    <FormControl>
      <FormLabel>복사 대상</FormLabel>

      <FormGroup>
        {optionList.map(({ value, label, disabled }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                // (typescript의 한계로) union type의 return type을 정확히 추론하지 못하므로 타입 단언을 해줌
                checked={copyTargetRecord[value] as ReturnTypeOfUnion<T>}
                name={value}
                onChange={handleCheckboxChange}
                disabled={disabled}
                size="small"
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
