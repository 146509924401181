import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";

import {
  AdminBidDetail,
  BidCopyStatus,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";

import {
  getBidCopyTargetOptionListForExportation,
  getCopyStatusOptionListForExportation,
  getDefaultCopyTargetRecordForExportation,
  getInitialCopyStatusForExportation,
} from "./utils";

import BidStatusOfNewBid from "./_components/BidStatusOfNewBid";
import CopyTargetOfNewBid from "./_components/CopyTargetOfNewBid";
import SubmitSection from "./_components/SubmitSection";

export default function ForExportation({
  showsBidCopyModal,
  setShowsBidCopyModal,
  bidDetail,
  hasExporterInfo,
}: {
  showsBidCopyModal: boolean;
  setShowsBidCopyModal: Dispatch<SetStateAction<boolean>>;
  bidDetail: AdminBidDetail;
  hasExporterInfo: boolean;
}) {
  const { status, projectStatus } = bidDetail;

  /**
   * 원본의뢰의 상태에 따른 `복사될 의뢰의 status 초기값`
   */
  const initialCopyStatus = useMemo(() => {
    return getInitialCopyStatusForExportation({ status, projectStatus });
  }, [projectStatus, status]);

  const [selectedCopyStatus, setSelectedCopyStatus] =
    useState<BidCopyStatus>(initialCopyStatus);

  /**
   * 신규 복사의뢰의 상태에 따른 `복사될 의뢰에서 복사대상이될 Record 기본값`
   */
  const defaultCopyTargetRecord = useMemo(() => {
    return getDefaultCopyTargetRecordForExportation(selectedCopyStatus);
  }, [selectedCopyStatus]);

  const [copyTargetRecord, setCopyTargetRecord] = useState(
    defaultCopyTargetRecord
  );

  const copyStatusOptionList = useMemo(() => {
    return getCopyStatusOptionListForExportation(initialCopyStatus);
  }, [initialCopyStatus]);

  const optionList = useMemo(() => {
    return getBidCopyTargetOptionListForExportation(
      selectedCopyStatus,
      hasExporterInfo
    );
  }, [hasExporterInfo, selectedCopyStatus]);

  return (
    <>
      <Modal
        modalBodySx={{ p: 2, overflow: "visible" }}
        isOpened={showsBidCopyModal}
        handleClose={() => setShowsBidCopyModal(false)}
        modalBody={
          <Box display="flex" flexDirection={"column"}>
            <Typography variant="h5">의뢰 복사</Typography>

            <Divider sx={{ mt: 1, mb: 2 }} />

            <Box>
              <Box display={"flex"} gap={3}>
                <BidStatusOfNewBid
                  selectedCopyStatus={selectedCopyStatus}
                  setSelectedCopyStatus={setSelectedCopyStatus}
                  copyStatusOptionList={copyStatusOptionList}
                />

                <Divider flexItem orientation="vertical" />

                <CopyTargetOfNewBid
                  copyTargetRecord={copyTargetRecord}
                  setCopyTargetRecord={setCopyTargetRecord}
                  defaultCopyTargetRecord={defaultCopyTargetRecord}
                  optionList={optionList}
                />
              </Box>

              <SubmitSection
                bidDetail={bidDetail}
                copyTargetRecord={copyTargetRecord}
                setShowsBidCopyModal={setShowsBidCopyModal}
                selectedCopyStatus={selectedCopyStatus}
              />
            </Box>
          </Box>
        }
      />
    </>
  );
}
