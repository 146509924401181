import { ChangeEvent } from "react";
import { FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";

import { BidCopyStatus } from "@sellernote/_shared/src/types/forwarding/adminBid";

export default function BidStatusOfNewBid({
  selectedCopyStatus,
  setSelectedCopyStatus,
  copyStatusOptionList,
}: {
  selectedCopyStatus: BidCopyStatus;
  setSelectedCopyStatus: (v: BidCopyStatus) => void;
  copyStatusOptionList: {
    name: BidCopyStatus;
    label: string;
    disabled?: boolean;
  }[];
}) {
  const handleBidCopyStatusChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedCopyStatus(value as BidCopyStatus);
  };

  return (
    <RadioGroup value={selectedCopyStatus} onChange={handleBidCopyStatusChange}>
      <InputLabel>신규 의뢰 상태</InputLabel>

      {copyStatusOptionList.map(({ name, label, disabled }) => (
        <FormControlLabel
          key={name}
          value={name}
          control={<Radio disabled={disabled} size="small" />}
          label={label}
        />
      ))}
    </RadioGroup>
  );
}
